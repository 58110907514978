<template>
  <div v-if="approvalData">
    <!-- 주의사황 -->
    <div class="info-container">
      <!-- 주의사황 -->
      <div style="padding: 24px">
        <div class="flex-align-center">
          <div class="content-circle"></div>
          <span class="content-info"
            >본 계약서는 매달 자동결제 플랫폼에서 서비스 판매를 진행하기 위해
            필수적인 판매조건을 동의하고 합의하는 절차입니다.</span
          >
        </div>
        <div class="flex-align-center mt-8">
          <div class="content-circle"></div>
          <span class="content-info"
            >계약자 정보 및 모든 계약 내용을 확인하고 동의해주세요.</span
          >
        </div>
        <div class="flex-align-center mt-8">
          <div class="content-circle"></div>
          <span class="content-info"
            >계약내용과 관련 문의사항은 파트너 고객센터(카카오채널)로 문의주시기
            바랍니다.</span
          >
        </div>
      </div>
    </div>

    <!-- 계약자 기본정보 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약자 기본정보</span>
        </div>
      </div>
      <!-- 관리자 유형 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 유형</span>
        <span>{{ getCompStatus(approvalData.adminType) }}</span>
      </div>
      <!-- 관리자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">관리자 이름</span>
        <span>{{ approvalData.name }} {{ approvalData.gender }}</span>
      </div>
      <!-- 휴대폰번호 -->
      <div class="info-content-wrap">
        <span class="content-category">휴대폰번호</span>
        <span>{{ formatPhone(approvalData.phone) }}</span>
      </div>
      <!-- 아이디 -->
      <div class="info-content-wrap">
        <span class="content-category">아이디</span>
        <span>{{ approvalData.email }}</span>
      </div>
      <!-- 거주지역 -->
      <div class="info-content-wrap">
        <span class="content-category">거주지역</span>
        <span>{{ approvalData.addr }} {{ approvalData.addr2 }}</span>
      </div>
      <!-- 활동지역 -->
      <div class="info-content-wrap">
        <span class="content-category">활동지역</span>
        <span>{{ approvalData.busiArea }}</span>
      </div>
      <!-- 분야 -->
      <div class="info-content-wrap">
        <span class="content-category">분야</span>
        <span
          >{{ approvalData.highSpecialty }} >
          {{ approvalData.lowSpecialty }}</span
        >
      </div>
      <div class="info-check-wrap">
        <input-check v-model="checkBasic">
          계약자의 기본정보가 맞습니다.
        </input-check>
      </div>
    </div>

    <!-- 계약 사업자 정보 -->
    <div class="info-container" v-if="approvalData.adminType === 'C'">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 사업자 정보</span>
        </div>
      </div>
      <!-- 상호명 -->
      <div class="info-content-wrap">
        <span class="content-category">상호명</span>
        <span>{{ approvalData.compName }}</span>
      </div>
      <!-- 사업자등록번호 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자등록번호</span>
        <span>{{ approvalData.compRegNumber }}</span>
      </div>
      <!-- 대표자 이름 -->
      <div class="info-content-wrap">
        <span class="content-category">대표자 이름</span>
        <span>{{ approvalData.compCeoName }}</span>
      </div>
      <!-- 사업장 소재지 -->
      <div class="info-content-wrap">
        <span class="content-category">사업장 소재지</span>
        <span>{{ approvalData.compAddr }} {{ approvalData.compAddr2 }}</span>
      </div>
      <!-- 사업자 구분 -->
      <div class="info-content-wrap">
        <span class="content-category">사업자 구분</span>
        <span>{{ approvalData.compClass }}</span>
      </div>
      <!-- 업태 -->
      <div class="info-content-wrap">
        <span class="content-category">업태</span>
        <span>{{ approvalData.compStatus }}</span>
      </div>
      <!-- 업종 -->
      <div class="info-content-wrap">
        <span class="content-category">업종</span>
        <span>{{ approvalData.compType }}</span>
      </div>
      <div class="info-check-wrap">
        <input-check v-model="checkCompany">
          계약자의 사업자정보가 맞습니다.
        </input-check>
      </div>
    </div>

    <!-- 계약 내용 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 내용</span>
        </div>
      </div>
      <!-- 계약명 -->
      <div class="info-content-wrap">
        <span class="content-category">계약명</span>
        <span>{{ approvalData.contractTitle }}</span>
      </div>
      <!-- 내용 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">내용</span>
        <div
          style="height: 600px; overflow: auto"
          v-html="approvalData.contractContent"
        />
      </div>
      <div class="info-check-wrap">
        <input-check v-model="checkContent">
          위 계약내용에 동의합니다.
        </input-check>
      </div>
    </div>

    <!-- 계약 세부사항 -->
    <div class="info-container">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category">계약 세부사항</span>
        </div>
      </div>
      <!-- 기간 -->
      <div class="info-content-wrap">
        <span class="content-category">기간</span>
        <span>{{ approvalData.contractPeriod }}개월</span>
      </div>
      <!-- 자동결제 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">자동결제 수수료 (VAT포함)</span>
        <span>{{ approvalData.commission }}%</span>
      </div>
      <!-- 해지위약금 수수료 (VAT포함) -->
      <div class="info-content-wrap">
        <span class="content-category">해지위약금 수수료 (VAT포함)</span>
        <span>{{ approvalData.cancelPenalty }}%</span>
      </div>
      <!-- 특약사항 -->
      <div class="info-content-wrap" style="align-items: flex-start">
        <span class="content-category">특약사항</span>
        <span v-html="approvalData.specialContent" />
      </div>
      <div class="info-content-agree">상기 모든 사항에 확인 및 동의하며, 등록한 결제항목이 판매 중이라도 법적 준수사항 위반 및 사회적문제 등으로 문제가 될 경우에는 안내(알림)를 진행하고 판매중단 될 수 있습니다.</div>
      <div class="info-check-wrap">
        <input-check v-model="checkDetail">
          위 계약 세부사항에 동의합니다.
        </input-check>
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <div class="warning fr-12 mr-32">
        {{ errorMsg }}
      </div>
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goContractStatusList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="doContract"
          >계약신청</button-common
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContractReg",
  mixins: [],
  components: {},

  /*** data ***/
  data() {
    return {
      isContractCheck: false, // 계약 신청 가능한지 체크
      approvalData: null,
      rejectReason: "",
      checkBasic: false,
      checkCompany: false,
      checkContent: false,
      checkDetail: false,
      errorMsg: "",
    };
  },

  /*** mounted ***/
  async mounted() {
    await this.checkContract();
    await this.getContractNewData();
  },

  /*** computed ***/
  computed: {
    checkValid() {
      return (
        this.isContractCheck &&
        this.checkBasic &&
        this.checkCompany &&
        this.checkContent &&
        this.checkDetail
      );
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 신규 계약 내용 상세 조회 ***/
    async getContractNewData() {
      const rs = await this.$axios({
        url: "/partner/contract/new/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.approvalData = rs.resultData;
        this.checkCompany = this.approvalData.adminType !== "C";
      } else {
        console.log("신규 계약 내용 상세 정보 조회 실패");
      }
    },

    /** @ 계약 체크 ***/
    async checkContract() {
      const rs = await this.$axios({
        url: "/partner/contract/new/check",
        params: {},
      });
      if (rs.result === "SUC") {
        this.isContractCheck = true;
      } else {
        this.isContractCheck = false;
        if (rs.result === "DUPL") {
          await this.$openAlert(
            {
              bodyTx:
                "기존 계약신청을 처리중입니다.<br/>문의사항이 있다면 고객센터에 요청해주세요.",
              btnScndNm: "닫기",
            },
            this
          );
        }
        if (rs.result === "USED") {
          await this.$openAlert(
            {
              bodyTx:
                "현재 계약중인 내역이 있어<br/>신규 계약신청을 할 수 없습니다.",
              btnScndNm: "닫기",
            },
            this
          );
        }
        if (rs.result === "EMP") {
          await this.$openAlert(
            {
              bodyTx:
                "계약설정 미완료로 신청이 어렵습니다.<br/>본사 고객센터에 요청해주세요.",
              btnScndNm: "닫기",
            },
            this
          );
        }
      }
    },

    /** @ 계약 신청 ***/
    async doContract() {
      const rs = await this.$axios({
        url: "/partner/contract/new/application",
        params: {},
      });
      if (rs.result === "SUC") {
        this.goContractStatusList();
      } else {
        if (rs.result === "DUPL") {
          await this.$openAlert(
            {
              bodyTx:
                "기존 계약신청을 처리중입니다.<br/>문의사항이 있다면 고객센터에 요청해주세요.",
              btnScndNm: "닫기",
            },
            this
          );
        }
        if (rs.result === "USED") {
          await this.$openAlert(
            {
              bodyTx:
                "현재 계약중인 내역이 있어<br/>신규 계약신청을 할 수 없습니다.",
              btnScndNm: "닫기",
            },
            this
          );
        }
        if (rs.result === "EMP") {
          await this.$openAlert(
            {
              bodyTx:
                "계약설정 미완료로 신청이 어렵습니다.<br/>본사 고객센터에 요청해주세요.",
              btnScndNm: "닫기",
            },
            this
          );
        }
      }
    },

    getAppType(type) {
      switch (type) {
        case "APPLY":
          return "신규";
        case "CANCEL":
          return "해지";
      }
    },

    getCompStatus(type) {
      switch (type) {
        case "C":
          return "사업자";
        case "P":
          return "개인";
      }
    },

    getApprovalStatus(type) {
      switch (type) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
      }
    },

    getContractStatus(type) {
      switch (type) {
        case "USED":
          return "계약중";
        case "END":
          return "계약만료";
        case "TERM":
          return "중도해지";
      }
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },

    goContractStatusList() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.content-circle {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #515466;
  margin-right: 5px;
}
.content-info {
  font-size: 12px;
  font-weight: 400;
  color: #515466;
}
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  margin-top: 10px;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-used {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-term {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-end {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.contract-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  min-width: 250px;
}
.content-edit-info {
  margin-left: 32px;
  color: #ff755a;
  font-weight: 500;
  font-size: 14px;
}
.info-content-status-app {
  color: #2CCDC3;
}
.info-content-status-rej {
  color: #f1431d;
}
.info-content-date {
  margin-left: 8px;
  font-size: 12px;
  color: #515466;
}
.info-check-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
}
.info-content-agree {
  color: #515466;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
}
</style>
